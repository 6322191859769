import React, { useState } from "react";
import styles from "./services.module.scss";
import CustomButton from "@/components/CustomButton";
import { useResize } from "@/hooks/use-resize";
import Image from "next/image";
import { ourServices } from "@/utils/const";

const Services = ({ customRef }) => {
  const { isScreenLg } = useResize();

  const [list, setList] = useState(ourServices);

  const changeList = (id, active) =>
    setList((state) =>
      state.map((item) => ({
        ...item,
        active: item.title === id ? active : false,
      })),
    );

  return (
    <section className={styles.wrapperServices} ref={customRef}>
      <div>
        <h2>Наши услуги</h2>

        <div className={styles.services}>
          {list.map((item, index) => (
            <div
              key={item.title}
              className={`${styles.wrapperService} ${
                !isScreenLg ? (item.active ? styles.active : "") : ""
              }`}
              onClick={() => changeList(item.title, !item.active)}
            >
              <div className={styles.service}>
                <Image
                  src={item.src}
                  fill
                  className="object-cover"
                  alt={item.title}
                  sizes="(min-width: 992px) 33vw, 100vw"
                />
                <h4>
                  <span>0{index + 1}. </span>
                  {item.title}
                </h4>

                <ul>
                  {item.descrtiption.map((subitem) => (
                    <li key={subitem}>{subitem}</li>
                  ))}
                </ul>
              </div>
              <CustomButton
                className="text-center"
                anim={true}
                theme="main"
                link={item.link}
              >
                Подробнее
              </CustomButton>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
