import Image from "next/image";
import React from "react";
import styles from "./sliderAboutUs.module.scss";
import CustomButton from "@/components/CustomButton";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import image from "@/public/home/example.webp";

import "swiper/css";
import "swiper/css/pagination";

const SliderAboutUs = ({ customRef, active }) => {
  const list = [
    {
      title: "ОАО “БПА Белстройиндустрия”",
      description: `Хотим выразить свою благодарность за помощь и постоянную поддержку команде Web Space. Обращались к ним впервые. Ребята - профессионалы высокого класса, Умеет все! Была выполнена большая работа по разработке, дизайну, наполнению и обратотке исходников. Реализовали весь запрошенный функционал на языке Next.JS.`,
      author: "Заместитель Директора Хилько В.И.",
      src: image,
      href: "https://bpabsi.by/",
    },
    {
      title: "Сдача Металлолома",
      description: `Сайт был сделан в считанные дни. Буквально в среду созвонился с Менеджером, а к пятнице был готовый Лендинг, с дизайном и наполнением. К понедельнику уже готовая реклама. Команда - Молодцы! Появились новые клиента и сайт окупился в первый же месяц, Спасибо.`,
      author: "Директор Лаппо О.О.",
      src: "",
      href: "https://metallolom74.by/",
    },
  ];

  return (
    <section className={styles.wrapperAboutUs} ref={customRef}>
      <div>
        <h2>Что о нас говорят</h2>

        <div>
          <Swiper
            className="dots-black desktop"
            spaceBetween={20}
            pagination={{ clickable: true }}
            modules={[Pagination]}
          >
            {list.map((item) => (
              <SwiperSlide key={item.title}>
                <div className={styles.aboutUs}>
                  <div className={styles.quoteBlock}>
                    <div className={styles.quote}>
                      <div>
                        <div>
                          <h4>{item.title}</h4>
                          <p>{item.description}</p>
                        </div>
                        <p className={styles.author}>{item.author}</p>
                      </div>
                      <Link
                        href={item.href}
                        className={styles.mobileLink}
                        target="_blank"
                      >
                        Перейти на сайт
                      </Link>
                    </div>
                    <CustomButton
                      onClick={() => window.open(item.href, "_blank")}
                      theme="main"
                    >
                      Перейти на сайт
                    </CustomButton>
                  </div>
                  <div
                    className={`${styles.picture} ${
                      active ? styles.active : ""
                    }`}
                  >
                    {item.src && (
                      <Image
                        alt={item.title}
                        src={item.src}
                        width={683}
                        height={573}
                        placeholder="blur"
                      />
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SliderAboutUs;
