import React, { useMemo } from "react";
import styles from "./stepsWork.module.scss";
import { stepsWork } from "@/utils/const";
import Image from "next/image";
import dynamic from "next/dynamic";
import { useResize } from "@/hooks/use-resize";

const StepsSlider = dynamic(() => import("./StepsSlider"), { ssr: false });

const StepsWork = ({ customRef }) => {
  const { isScreenLg } = useResize();

  const stepsForLaptop = useMemo(
    () =>
      stepsWork.map((item, index) => (
        <li key={index} className={styles.step}>
          <span>0{index + 1} </span>
          <h4>{item.title} </h4>
          <Image
            src={item.src}
            alt={item.title}
            loading="lazy"
            fill
            className="object-cover"
          />
        </li>
      )),
    [],
  );

  return (
    <section className={styles.wrapperStepsWork} ref={customRef}>
      <div>
        <h2>Этапы работы</h2>
        <div className={styles.circle}></div>

        <ul className={`${styles.stepsWork}`}>{stepsForLaptop}</ul>
        {!isScreenLg && <StepsSlider />}
      </div>
    </section>
  );
};

export default StepsWork;
