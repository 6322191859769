import React, { useRef } from "react";
import styles from "./presentationSlide.module.scss";
import CustomButton from "@/components/CustomButton";
import { leftAnimation } from "@/utils/helper";
import Image from "next/image";
import office from "/public/background/office.webp";

import dynamic from "next/dynamic";

const Motionli = dynamic(() =>
  import("@/components/CustomMotion").then((mod) => mod.Motionli),
);

const AnimationCircle = dynamic(() => import("./AnimationCircle"), {
  ssr: false,
});

const PresentationSlide = () => {
  const list = [
    "Уникальный дизайн",
    "Чистый код",
    "Полное сопровождение",
    "Подключение CMS",
  ];

  const ref = useRef();

  return (
    <section className={styles.wrapperSlide} ref={ref}>
      <div className={styles.slide}>
        <h1>Разработка продающих сайтов</h1>
        <AnimationCircle innerRef={ref} />
        <ul className="grid lg:hidden">
          {list.map((item, index) => (
            <Motionli
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              key={index}
              custom={index + 1}
              variants={leftAnimation}
            >
              <div />
              <span>{item}</span>
            </Motionli>
          ))}
        </ul>
        <ul className="lg:grid lgMin:hidden">
          {list.map((item, index) => (
            <li key={index}>
              <div />
              <span>{item}</span>
            </li>
          ))}
        </ul>
        <div className={styles.actionsButton}>
          <CustomButton maxWidth="355px" theme="main">
            Получить консультацию
          </CustomButton>
          <CustomButton maxWidth="355px" theme="black" link="price-calculator">
            Расчет сайта
          </CustomButton>
        </div>
      </div>
      <Image
        placeholder="blur"
        quality={100}
        src={office}
        alt="Разработка продающих сайтов"
        priority
        fill
        sizes="100vw"
        className="z-[-1] object-cover"
      />
    </section>
  );
};

export default PresentationSlide;
