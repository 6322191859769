import MainPage from "@/components/screens/MainPage";
import Head from "next/head";
import React from "react";

const index = () => {
  function addHomeBreadcrumbJsonLd() {
    return {
      __html: `{
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "web-space.by",
        "item": "https://web-space.by"
      }, {
        "@type": "ListItem",
        "position": 2,
        "name": "📞 +375 (44) 752-44-85",
        "item": "https://web-space.by/#" 
      }]
    }
  `,
    };
  }

  return (
    <>
      <Head>
        <title>Разработка сайтов под ключ в Минске</title>
        <meta
          name="description"
          content={`Разработка сайтов под ключ от 7 дней. Индивидуальный дизайн, официальное оформление, Новейшие технологии.
        Разработка веб-приложений. Реклама в интернете, SEO, SMM продвижение, контекстная и таргетированная реклама`}
        />
        <meta
          name="keywords"
          content="Сайт под ключ, Веб-приложение, Разработка веб-сайта, Продающий сайт, Компания Web Space, Веб Спейс"
        />
        <meta property="og:url" content="https://web-space.by/" />
        <meta
          property="og:title"
          content="Разработка сайтов под ключ в Минске"
        />
        <meta
          property="og:description"
          content={`Разработка сайтов под ключ от 7 дней. Индивидуальный дизайн, официальное оформление, Новейшие технологии.
        Разработка веб-приложений. Реклама в интернете, SEO, SMM продвижение, контекстная и таргетированная реклама`}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={addHomeBreadcrumbJsonLd()}
          key="product-jsonld"
        />
      </Head>
      <MainPage />
    </>
  );
};

export default index;
