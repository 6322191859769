import React, { useRef, useState } from "react";
import PresentationSlide from "./components/PresentationSlide";
import StepsWork from "./components/StepsWork/StepsWork";
import SliderAboutUs from "./components/SliderAboutUs/SliderAboutUs";
import Workers from "./components/Workers";
import DiscussProject from "./components/DiscussProject";
import CardsTypesSites from "@/components/CardsTypesSites/CardsTypesSites";
import Services from "./components/Services";

import dynamic from "next/dynamic";
import { useResize } from "@/hooks/use-resize";

const AnimationCircle = dynamic(() => import("./components/AnimationCircle"), {
  ssr: false,
});

const MainPage = () => {
  const { isScreenLg } = useResize();
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);
  const [activeWorker, setActiveWorker] = useState(false);
  const [activeSliderAboutUs, setActiveSliderAboutUs] = useState(false);

  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  return (
    <>
      {isScreenLg && (
        <AnimationCircle
          refCardsSites={ref}
          refStepWork={ref2}
          refWorkers={ref3}
          refAboutUs={ref4}
          setActive={setActive}
          active={active}
          active2={active2}
          setActive2={setActive2}
          active3={activeWorker}
          active4={activeSliderAboutUs}
          setActive3={setActiveWorker}
          setActive4={setActiveSliderAboutUs}
        />
      )}
      <PresentationSlide />
      <Services />
      <DiscussProject />

      <CardsTypesSites
        customRef={ref}
        urgentOpening={active2}
        slider={true}
        activeStart={active}
      />

      <StepsWork customRef={ref2} />

      <Workers customRef={ref3} active={activeWorker} />
      <SliderAboutUs customRef={ref4} active={activeSliderAboutUs} />
    </>
  );
};

export default MainPage;
