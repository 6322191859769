import React, { useContext, useRef, useState } from "react";
import { typesSytes } from "@/utils/const";
import Image from "next/image";
import styles from "./cardsTypesSites.module.scss";
import { useResize } from "@/hooks/use-resize";
import CustomButton from "../CustomButton";
import { ModalContext } from "@/components/Context";
import Link from "next/link";
import { useRouter } from "next/router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Thumbs } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

const CardsTypesSites = ({
  slider = false,
  urgentOpening = false,
  activeStart = true,
  customRef = null,
}) => {
  const { isScreenLg } = useResize();
  const { push } = useRouter();
  const [idActiveControl, setIdActiveControl] = useState(typesSytes[0].title);
  const [idActiveType, setIdActiveType] = useState(typesSytes[0].title);
  const { setActiveModalCallback } = useContext(ModalContext);
  const [activeReverseAnim, setActiveReverseAnim] = useState(false);

  const changeActiveType = (id) => () => {
    if (id !== "Ваш запрос") {
      if (activeReverseAnim) {
        setIdActiveType(id);
      } else {
        setActiveReverseAnim(true);
      }

      setTimeout(() => {
        setIdActiveType(id);
        setTimeout(() => setActiveReverseAnim(false), 10);
      }, 700);
      setIdActiveControl(id);
    } else {
      setActiveModalCallback(true);
    }
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <section
      ref={customRef}
      className={`${styles.wrapperTypesWebsites} ${
        slider ? styles.slider : null
      }`}
    >
      <div>
        <h2>Виды веб-сайтов</h2>
        {slider ? (
          <div>
            <ul className={styles.control}>
              {typesSytes.concat({ title: "Ваш запрос" }).map((item) => (
                <li
                  key={item.title}
                  onClick={changeActiveType(item.title)}
                  className={
                    idActiveControl === item.title ? styles.active : ""
                  }
                >
                  {item.title}
                </li>
              ))}
            </ul>

            <div className={styles.controlMobile}>
              <Swiper
                slidesPerGroup={1}
                onSwiper={setThumbsSwiper}
                slidesPerView={2}
                watchSlidesProgress={true}
                spaceBetween={12}
                modules={[Navigation, Thumbs]}
                onTouchEnd={(swiperCore) => {
                  swiperRef?.current?.slideTo(swiperCore.realIndex);
                  setActiveSlide(swiperCore.realIndex);
                }}
                slideToClickedSlide={true}
              >
                {typesSytes.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className={`
                        ${activeSlide === index ? "bg-black text-white" : ""}
                        flex h-[35px] items-center justify-center border-b border-black
                      `}
                    >
                      {item.title}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        ) : null}
        <div className={styles.typesWebsites}>
          {isScreenLg ? (
            (slider
              ? typesSytes.filter((item) => item.title === idActiveType)
              : typesSytes
            ).map((item) => (
              <div key={item.title} className={styles.type}>
                <div
                  className={`${styles.picture} ${
                    activeReverseAnim ? styles.anim : ""
                  } ${
                    isScreenLg && !urgentOpening
                      ? activeStart
                        ? styles.activeStart
                        : styles.noActiveStart
                      : ""
                  }`}
                >
                  <Image
                    alt={item.src}
                    src={item.src}
                    width={item.width}
                    height={item.height}
                  />
                </div>
                <div className={styles.description}>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                  {slider ? (
                    <CustomButton
                      onClick={() => push("/promotion")}
                      theme="white"
                    >
                      Подробнее
                    </CustomButton>
                  ) : null}
                </div>
              </div>
            ))
          ) : (
            <Swiper
              spaceBetween={20}
              className={"dots-white max-w-full"}
              pagination={{ clickable: true }}
              modules={[Pagination, Navigation, Pagination, Thumbs]}
              thumbs={{ swiper: thumbsSwiper }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              onSlideChange={(swiperCore) => {
                setActiveSlide(swiperCore.activeIndex);
              }}
            >
              {typesSytes.map((item) => (
                <SwiperSlide key={item.title}>
                  <div className={styles.type}>
                    <div className={styles.picture}>
                      <Image
                        alt={item.src}
                        src={item.src}
                        width={item.width}
                        height={item.height}
                      />
                    </div>
                    <div className={styles.description}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                      <Link className="w-fit" href="/promotion">
                        Подробнее
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          {slider ? null : (
            <CustomButton
              theme="main-border"
              size="big"
              onClick={() => setActiveModalCallback(true)}
            >
              Ваш вариант
            </CustomButton>
          )}
        </div>
      </div>
    </section>
  );
};

export default CardsTypesSites;
