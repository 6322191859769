"use client";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import styles from "./workers.module.scss";
import { useResize } from "@/hooks/use-resize";
import dynamic from "next/dynamic";

const Popover = dynamic(() => import("antd/lib/popover"));

const Worker = ({ item, isScreenLg, setActiveAnim, activeAnim, isLoading }) => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);

  return !isScreenLg && isLoading ? (
    <Popover
      key={item.title}
      title={!isScreenLg && <h5 className={styles.title}>{item.title}</h5>}
      trigger="click"
      getTooltipContainer={() => ref.current}
      onOpenChange={() => {
        setActive(activeAnim ? false : !active);
        setActiveAnim(false);
      }}
      open={!isScreenLg ? activeAnim || active : false}
    >
      <div
        style={{ zIndex: active || activeAnim ? 4 : 3 }}
        className={styles.worker}
        ref={ref}
      >
        <h5>{item.title}</h5>
        <Image alt={item.title} src={item.src} width={200} height={200} />
      </div>
    </Popover>
  ) : (
    <div
      style={{ zIndex: active || activeAnim ? 4 : 3 }}
      className={styles.worker}
      ref={ref}
    >
      <h5>{item.title}</h5>
      <Image alt={item.title} src={item.src} width={200} height={200} />
    </div>
  );
};

const Workers = ({ customRef, active }) => {
  const list = [
    { title: "Менеджер проекта", src: "/home/manager.svg" },
    { title: "Маркетолог", src: "/home/marketolog.svg" },
    { title: "Seo-специалист", src: "/home/seo.svg" },
    { title: "Backend разработчик", src: "/home/backend.svg" },
    { title: "Frontend разработчик", src: "/home/frontend.svg" },
    { title: "Web-дизайнер", src: "/home/web-design.svg" },
  ];

  const { isScreenLg } = useResize();
  const [activeAnim, setActiveAnim] = useState(true);
  const [customIndex, setCustomIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeAnim && window.innerWidth < 992) {
      setTimeout(() => {
        setCustomIndex((state) => (state - 1 === -1 ? 5 : state - 1));
      }, 50000);
    }
  }, [activeAnim, customIndex]);

  useEffect(() => setIsLoading(true), []);

  return (
    <section className={styles.wrapperWorkers} ref={customRef}>
      <div>
        <h2>Кто работает над проектом</h2>
        <div className={styles.workers}>
          {list.map((item, index) => (
            <Worker
              key={item.title}
              item={item}
              isScreenLg={isScreenLg}
              setActiveAnim={setActiveAnim}
              activeAnim={activeAnim ? index === customIndex : false}
              isLoading={isLoading}
            />
          ))}
          <div className={`${styles.BigButton} ${active ? styles.active : ""}`}>
            Ваш проект
          </div>
        </div>
      </div>
    </section>
  );
};

export default Workers;
