import React, { useEffect, useState } from "react";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import styles from "./modalCollback.module.scss";
import CustomCheckBox from "../CustomCheckbox";
import { checkPhoneNumber } from "@/utils/helper";
import { useResize } from "@/hooks/use-resize";
import { sendMessage } from "src/lib/api";
import isEmpty from "validator/lib/isEmpty";
import { Controller, useForm } from "react-hook-form";

const ModalCallback = ({
  type = "basic",
  fullPage = false,
  displayGrid = false,
  description = false,
  activeModal = false,
  setActiveModal = () => {},
}) => {
  const fieldsFeedback = ["name", "phone"];

  const {
    handleSubmit,
    control,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: "",
      name: "",
      agree: false,
    },
  });

  const [loading, setLoading] = useState(false);

  const [messageApi, contextHolder] = useState(() => {});

  useEffect(() => {
    import("antd/lib/message").then((item) => {
      contextHolder(item.default);
    });
  }, []);

  const { isScreenLg } = useResize();

  const checkFullPage = (item, replacement) => (fullPage ? item : replacement);

  const currentActiveModal = checkFullPage(activeModal, true);

  const changeActiveModal = () => {
    setActiveModal(false);
  };

  const clickHandler = async (data) => {
    const checkEmptyName = isEmpty(data?.name);
    const checkEmptyPhone = isEmpty(data?.phone);
    const wrapperCheckPhoneNumber = !checkPhoneNumber(data?.phone);

    if (checkEmptyName)
      setError("name", { message: "поле не должно быть пустым" });

    if (checkEmptyPhone)
      setError("phone", { message: "поле не должно быть пустым" });
    else if (wrapperCheckPhoneNumber)
      setError("phone", { message: "некорректно введен номер телефона" });

    if (!checkEmptyName && !checkEmptyPhone && !wrapperCheckPhoneNumber) {
      setLoading(true);

      const response = await sendMessage({
        fields: { name: data.name, phone: data.phone },
        subject: "backCall",
      });

      if (response.ok) {
        messageApi.open({
          type: "success",
          className: "customMessage",
          content: "Сообщение успешно отправлено",
        });

        reset();
      } else {
        messageApi.open({
          type: "error",
          className: "customMessage",
          content: response.message,
        });
      }

      setLoading(false);
    }
  };

  const watchAgree = watch("agree", false);

  return currentActiveModal ? (
    <div
      className={fullPage ? styles.fullPage : ""}
      onClick={changeActiveModal}
    >
      <div
        className={`${styles.modal} ${styles[type]} ${
          displayGrid ? styles.grid : ""
        }`}
      >
        {checkFullPage(
          <button className={styles.buttonExit} onClick={setActiveModal} />,
          null,
        )}
        <form
          onClick={(e) => e.stopPropagation()}
          onSubmit={handleSubmit(clickHandler)}
        >
          <div>
            <h5>
              {displayGrid || fullPage ? "Обсудим проект?" : "Обратный звонок"}
            </h5>
            {description ? (
              <span>Пожалуйста заполните форму и мы с вами свяжемся</span>
            ) : null}
          </div>
          <div>
            {fieldsFeedback.map((item) => (
              <Controller
                key={item}
                control={control}
                name={item}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <CustomInput
                    type={item}
                    value={value}
                    bigPadding={displayGrid}
                    setValue={onChange}
                    error={error}
                  />
                )}
              />
            ))}

            <Controller
              control={control}
              name="agree"
              render={({ field: { onChange, value } }) => (
                <CustomCheckBox
                  value={value}
                  onChange={onChange}
                  consenst={true}
                />
              )}
            />
          </div>

          <CustomButton
            theme={
              (displayGrid || fullPage) && !isScreenLg
                ? "white"
                : checkFullPage("main", "black")
            }
            size={displayGrid ? "averange" : checkFullPage("averange", "small")}
            disabled={Object.keys(errors).length > 0 || !watchAgree}
            loading={loading}
          >
            Отправить
          </CustomButton>
        </form>
      </div>
    </div>
  ) : null;
};

export default ModalCallback;
