import ModalCallback from "@/components/ModalCallback";
import styles from "./discussProject.module.scss";
import React from "react";
import Image from "next/image";
import discuss from "@/public/home/discussProject.webp";

const DiscussProject = () => {
  return (
    <section className={styles.wrapperDiscussProject}>
      <div>
        <ModalCallback description={true} displayGrid={true} />
      </div>
      <Image
        src={discuss}
        placeholder="blur"
        alt="Обратная связь"
        sizes="100vw"
        fill
        className="z-[-1] object-cover lg:hidden"
      />
    </section>
  );
};

export default DiscussProject;
