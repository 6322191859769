"use client";
import React, { useContext } from "react";
import styles from "./customButton.module.scss";
import Link from "next/link";
import { ModalContext } from "../Context";
import dynamic from "next/dynamic";

const Spin = dynamic(() => import("antd/lib/spin"), { ssr: false });

const CustomButton = ({
  onClick = () => {},
  className = "",
  theme = "black",
  size = "averange",
  onlyMobile = false,
  children,
  anim = false,
  disabled = false,
  bigAnimation = false,
  loading = false,
  link = "",
}) => {
  const { setActiveModalCallback } = useContext(ModalContext);

  if (!!link) {
    return (
      <Link
        href={link}
        className={`${styles.customButton} ${styles[theme]} ${
          styles[size]
        } ${className} ${onlyMobile ? styles.onlyMobile : ""} ${
          anim ? styles.notAnim : ""
        } ${bigAnimation ? styles.bigAnimation : ""} ${
          loading ? styles.loading : ""
        }`}
      >
        <span>{children}</span>
      </Link>
    );
  } else {
    const clickHandler = (e) => {
      if (!loading) {
        onClick();
      }
      if (children === "Получить консультацию" || children === "Ваш вариант") {
        setActiveModalCallback(true);
      }
    };

    return (
      <button
        onClick={clickHandler}
        className={`${styles.customButton} ${styles[theme]} ${
          styles[size]
        } ${className} ${onlyMobile ? styles.onlyMobile : ""} ${
          anim ? styles.notAnim : ""
        } ${bigAnimation ? styles.bigAnimation : ""} ${
          loading ? styles.loading : ""
        }`}
        disabled={disabled}
      >
        <Spin className={styles.customSpin} spinning={loading} delay={500}>
          <span>{children}</span>
        </Spin>
      </button>
    );
  }
};

export default CustomButton;
