export const sendMessage = async ({ name, phone, fields = null, subject }) => {
  try {
    const res = await fetch(`/api/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        phone: phone,
        fields: fields,
        subject: subject,
      }),
    });

    const data = await res.json();
    return data;
  } catch (e) {
    console.log(e);
  }
};
