"use client";
import React, { useState } from "react";
import styles from "./customInput.module.scss";

const CustomInput = ({
  type,
  bigPadding = false,
  error = "",
  value = null,
  setValue = () => {},
  labelBlock = false,
}) => {
  const types = [
    { id: "name", title: "Ваше имя" },
    { id: "phone", title: "Номер телефона" },
    { id: "second name", title: "Ваша фамилия" },
  ];

  const currentType = types?.find((item) => item.id === type);

  const onChange = (e) => setValue(e.target.value);

  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0)
      ? `${styles.label} ${styles.labelFloat}`
      : styles.label;

  return (
    <div
      className={`${styles.wrapperCustomInput} ${!!error ? styles.error : ""} ${
        bigPadding ? styles.bigPadding : ""
      } ${styles.floatLabel} ${labelBlock ? styles.labelBlock : ""}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <input id={type} value={value} onChange={onChange} />
      <span>{error.message}</span>
      <label htmlFor={type} className={`${labelClass} `}>
        {currentType?.title}
      </label>
    </div>
  );
};

export default CustomInput;
