"use client";
import React, { useContext, useState } from "react";
import styles from "./customCheckbox.module.scss";
import Link from "next/link";
import checkbox from "@/public/home/checkbox.webp";
import Image from "next/image";
import { ModalContext } from "../Context";

const CustomCheckBox = ({
  title = "",
  value = false,
  onChange = () => {},
  error = false,
  consenst = false,
}) => {
  const changeValue = () => {
    onChange(!value);
  };
  const [activeHoverLink, setActiveHoverLink] = useState(false);
  const { setActiveModalCallback, activeModalCallback } =
    useContext(ModalContext);

  const currentTitle = consenst ? (
    <>
      <span>Согласие на обработку</span>{" "}
      <Link
        onClick={(e) => {
          e.stopPropagation();
          if (activeModalCallback) {
            setActiveModalCallback(false);
          }
        }}
        onMouseEnter={() => setActiveHoverLink(true)}
        onMouseLeave={() => setActiveHoverLink(false)}
        href="/privacy-policy"
        className="signature normal text-red"
      >
        персональных данных
      </Link>
    </>
  ) : (
    title
  );
  return (
    <button
      type="button"
      className={`${styles.checkbox} ${activeHoverLink ? styles.noHover : ""}`}
      onClick={changeValue}
    >
      <div className={`${styles.square} ${value ? styles.check : ""}`}>
        <Image
          src={checkbox}
          width={25}
          height={25}
          className="absolute top-0"
          alt="checkbox"
        />
      </div>
      <label
        className={`${error ? styles.error : ""} ${consenst ? `signature` : "font-light"}`}
      >
        {currentTitle}
      </label>
    </button>
  );
};

export default CustomCheckBox;
